import logo from './logo.svg';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import LoadingBar from 'react-top-loading-bar';

import axios from 'axios';
import { useEffect, useState, useRef } from 'react';
import Constants from './Support/Constants';
import Protected from './Support/Protected';
import { BigProgress } from './Components/Basics';


import Login from './Pages/Login';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import Logout from './Pages/Logout';
import NotFound from './Pages/NotFound';
import Dashboard from './Pages/Dashboard';
import Chat from './Pages/Chat';
import Chats from './Pages/Chats';
import VerifyEmail from './Pages/VerifyEmail';
import FPLink from './Pages/FPLink';
import FPReset from './Pages/FPReset';


function App() {

  const location = useLocation();

  const loadingRef = useRef(null);

  const [isLoggedIn, setIsLoggedIn] = useState(null);

  useEffect(() => {

    axios.get(Constants.apiUrl + `/login-checker`, {
      withCredentials: true
    })
      .then((e) => {

        global.apiKey = e.data.apiKey;

        if (e.data.isLoggedIn === true) {
          //window.location.replace("/projects");
          setIsLoggedIn(true);
        } else {
          setIsLoggedIn(false);
        }

      })
      .catch((err) => {

        setIsLoggedIn(false);

      });

  }, []);

  const updateLoggedIn = () => setIsLoggedIn(false);


  useEffect(() => {

    loadingRef.current.continuousStart();

    setTimeout(() => {
      loadingRef.current.complete();
    }, 300);


  }, [location]);

  return (

    isLoggedIn == null

      ?

      <>

        <LoadingBar color='#924119' height={4} ref={loadingRef} />
        <div className="h-screen">
          <BigProgress />
        </div>

      </>


      :

      <>

        <LoadingBar color='#924119' height={4} ref={loadingRef} />

        <Routes>

          <Route exact path='/' element={<Login isLoggedIn={isLoggedIn} />} />

          <Route exact path='login' element={<Login isLoggedIn={isLoggedIn} />} />

          <Route exact path='register' element={<Register isLoggedIn={isLoggedIn} />} />

          <Route exact path='verify-email/:token' element={<VerifyEmail />} />

          <Route exact path='forgot-password/link' element={<FPLink isLoggedIn={isLoggedIn} />} />

          <Route exact path='forgot-password/reset/:token' element={<FPReset isLoggedIn={isLoggedIn} />} />

          <Route exact path="dashboard"
            element={
              <Protected isLoggedIn={isLoggedIn}><Dashboard /></Protected>
            }
          />

          <Route exact path="chat"
            element={
              <Protected isLoggedIn={isLoggedIn}><Chat /></Protected>
            }
          />

          <Route exact path="chat/:id"
            element={
              <Protected isLoggedIn={isLoggedIn}><Chat /></Protected>
            }
          />



          <Route exact path="chats"
            element={
              <Protected isLoggedIn={isLoggedIn}><Chats /></Protected>
            }
          />



          <Route exact path="profile"
            element={
              <Protected isLoggedIn={isLoggedIn}><Profile /></Protected>
            }
          />

          <Route exact path="logout"
            element={
              <Protected isLoggedIn={isLoggedIn}><Logout updateLoggedIn={updateLoggedIn} /></Protected>
            }
          />

          <Route path='*' element={<NotFound />} />


        </Routes>

      </>

  );
}

export default App;
