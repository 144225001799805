import Main from "../Components/Main";
import { Input, H3, H5, Button, BigProgress } from "../Components/Basics";
import { useEffect, useRef, useState } from "react";
import { isEmpty } from "../Support/FormValidator";
import API from "../Support/API";

const Profile = () => {

    const formRef = useRef();

    const [errMsg, setErrMsg] = useState("");
    const [okMsg, setOkMsg] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {

        let to;

        API.get('profile')
            .then((e) => {

                setIsLoading(false);

                to = setTimeout(() => {
                    const { name, email,apiKey } = formRef.current;

                    name.value = e.profile.name;
                    email.value = e.profile.email;
                    apiKey.value = e.profile.apiKey;

                }, 100);
            });

        return () => to !== null ? clearTimeout(to) : ""

    }, []);

    const onUpdate = () => {

        setErrMsg("");
        setOkMsg("");

        if (isEmpty(["name"], formRef.current)) {
            setErrMsg("Please write the name");
            return;
        }

        const { name, password, newPassword, confirmPassword, apiKey } = formRef.current;


        if (password.value !== "") {

            if (newPassword.value.length < 8) {
                setErrMsg("Password should be atleast 8 characeters long.");
                return;
            }

            if (newPassword.value !== confirmPassword.value) {
                setErrMsg("Confirm Password does not match");
                return;
            }

        }

        if (name.value.length < 3) {
            setErrMsg("Name should be 3 characters long.");
            return;
        }

        if (apiKey.value.length === 0) {
            setErrMsg("Please enter your OpenAI API Key");
            return;
        }

        setIsBtnLoading(true);

        global.apiKey = apiKey.value;

        API.post("update-profile", {
            name: name.value,
            password: password.value,
            newPassword: newPassword.value,
            confirmPassword: confirmPassword.value,
            apiKey: apiKey.value
        })
            .then((e) => {

                setOkMsg("Updated");
                setIsBtnLoading(false);

                password.value = "";
                newPassword.value = "";
                confirmPassword.value = "";

            })
            .catch((err) => {
                setErrMsg(err.message);
                setIsBtnLoading(false);
            });
    }

    return (

        <Main active="profile">

            {

                isLoading ?


                    <BigProgress />


                    :

                    <div className="m-4 w-full">

                        <H3 className="mb-5">
                            Profile Settings
                        </H3>

                        <H5 className="my-5">
                               General
                            </H5>


                        <form ref={formRef} className="lg:w-1/3 w-full">

                            <Input

                                type="text"
                                label="Your Email"
                                name="email"
                                disabled={true}

                            />

                            <Input

                                type="text"
                                label="Your Name"
                                name="name"
                                important={true}

                            />

                            <Input

                                type="text"
                                label="Gen AI Key"
                                name="apiKey"
                                important={true}
                                autocomplete="one-time-code"

                            />

                            <div className="dark:text-white mb-4 text-sm -mt-3">Get OpenAI Key from <a className="dark:text-blue-400 text-blue-600 inline" href="https://platform.openai.com/account/api-keys" target="_blank">platform.openai.com/account/api-keys</a></div>

                            <div className="border-b border-gray-300 dark:border-gray-600"></div>

                            <H5 className="my-5">
                                Password (Optional)
                            </H5>


                            <Input

                                type="password"
                                label="Current Password"
                                name="password"

                            />

                            <Input

                                type="password"
                                label="New Password"
                                name="newPassword"
                                autocomplete="one-time-code"

                            />

                            <Input

                                type="password"
                                label="Retype New Password"
                                name="confirmPassword"
                                autocomplete="one-time-code"

                            />

                            {
                                errMsg && <div className="my-4"><span className="text-red-600">{errMsg}</span></div>
                            }

                            {
                                okMsg && <div className="my-4"><span className="text-green-600">{okMsg}</span></div>
                            }


                            <Button onClick={onUpdate} isLoading={isBtnLoading} className={"mt-2 w-full"}>
                                Update
                            </Button>

                        </form>


                    </div>

            }


        </Main>

    );


}

export default Profile;