import axios from "axios";
import Constants from "./Constants";


class API {

    static async get(path) {

        try {

            const call = await axios.get(Constants.apiUrl + "/" + path, {
                withCredentials: true
            });

            return call.data;


        } catch (err) {

            if (err.response && err.response.status === 401) {
                window.location.href = "login";
            }
            else if (err.response && err.response.data && err.response.data.msg) {
                throw new Error(err.response.data.msg);
            }
            else {
                throw new Error("Something went wrong, please try later.");
            }

        }

    }

    static async post(path, data) {

        try {

            const call = await axios.post(Constants.apiUrl + "/" + path, data, {
                withCredentials: true
            });

            return call.data;

        } catch (err) {

            if (err.response && err.response.status === 401) {
                window.location.href = "login";
            }
            else if (err.response && err.response.data && err.response.data.msg) {
                throw new Error(err.response.data.msg);
            }
            else {
                throw new Error("Something went wrong, please try later.");
            }

        }

    }

}


export default API;