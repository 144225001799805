import { Link } from "react-router-dom";
import Constants from "../Support/Constants";
import logo from "../Images/logo.png";
import { useState } from "react";
import { DarkModeButton } from "./Basics";


export default function NavBarAuth() {


    const [isDarkMode, setDarkMode] = useState(() => {

        if (localStorage.getItem("dark") === "true") {
          document.documentElement.classList.add("dark");
          return true;
        } else {
          return false;
        }
    });

    const toggleDarkMode = (x) => {
        localStorage.setItem("dark", (x === true ? 'false' : 'true'));
        setDarkMode((x) => !x);
        document.documentElement.classList.toggle("dark");
    };

    return (


        <nav className="mb-96 bg-white dark:bg-gray-900 fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600">

            <div className="max-w-screen-3xl flex flex-wrap items-center justify-between mx-auto p-4">

                <Link to="/" className="flex items-center">
                    <img src={logo} width={47} className="mr-2 shadow-lg rounded-full" />
                    <span className="self-center lg:text-2xl sm:text-xl font-semibold whitespace-nowrap dark:text-white">{Constants.appName}</span>
                </Link>

                <DarkModeButton
                        isDarkMode={isDarkMode}
                        onClick={() => toggleDarkMode(isDarkMode)}                       
                    />



            </div>
        </nav>


    );
}