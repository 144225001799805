import Main from "../Components/Main";
import { H3, H2, DangerAlert, DashboardCard, APIProgress, LinearProgress } from "../Components/Basics";
import { GreenBadge } from "../Components/Badges";
import { useEffect, useRef, useState } from "react";
import API from "../Support/API";
import { RiSignalTowerFill, RiParkingBoxLine, RiTimeLine } from "react-icons/ri";
import {HiChatBubbleOvalLeft} from 'react-icons/hi2';
import dateFormatter from "dateformat";
import { Link } from "react-router-dom";
import lunaImage from "../Images/luna.png";
import {AiOutlineMessage} from "react-icons/ai";
import {BiChat} from "react-icons/bi";

const Dashboard = () => {

    const [cardsValue, setCardsValue] = useState({
        totalMessages: 0,
        totalChats: 0,
        thisMonthChats: 0
    });

    const [isLoading, setIsLoading] = useState(true);

    const [chats, setChats] = useState(null);

    const [apiKey, setApiKey] = useState(null);

    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const formatNum = (number) => {
        if (number >= 1000000) {
            return (number / 1000000).toFixed(1) + 'M';
        } else if (number >= 1000) {
            return (number / 1000).toFixed(1) + 'K';
        } else {
            return parseInt(number)
        }
    }

    useEffect(() => {

        API.get('dashboard')
            .then((e) => {

                setCardsValue({
                    totalMessages: e.data.totalMessages,
                    totalChats: e.data.totalChats,
                    thisMonthChats: e.data.thisMonthChats,
                });

                setChats(e.data.recentChats);

                setApiKey(e.data.apiKey);
                setIsEmailVerified(e.data.isEmailVerified);

                setIsLoading(false);

            })
            .catch((err) => {

            })

    }, []);

    const dateFormat = (date) => {

        const now = new Date(date);

        const newDate = dateFormatter(now, "mmmm dd, yyyy");

        return newDate;
    }


    return (

        <Main active="dashboard">

            {
                isLoading ?

                    <APIProgress />


                    :


                    <div className="max-w-6xl mx-auto px-3">

                        <H3 className="mt-4">Dashboard</H3>


                        {
                            isEmailVerified === 0 &&
                            <div className="my-5">
                                <DangerAlert>We have sent a verification link to your email. Please verify your email to continue using Luna Chat.</DangerAlert>
                            </div>
                        }

                        {
                            apiKey === null &&
                            <div className="my-5">
                                <DangerAlert>Please add your OpenAI API Key from <Link className="font-bold" to={'/profile'}>profile</Link> page.</DangerAlert>
                            </div>
                        }

                        <div className="bg-amber-800 p-5 my-5 rounded-lg">

                            <div className="flex flex-col">

                                <div className="flex items-center justify-center my-4 flex-wrap lg:flex-nowrap">

                                    <div className="flex flex-col justify-center items-center mb-4 lg:mb-0">
                                        <img src={lunaImage} width={80} className="rounded-full" />
                                        <Link to={"/chat"}><button className="w-36 text-white bg-amber-900 font-medium rounded text-sm px-5 py-2.5 text-center mt-5">Chat Now</button></Link>
                                    </div>

                                    <div className="text-white lg:ml-5 lg:text-left text-center">
                                        Welcome to Luna, your personal AI chatbot therapist. I'm here to help you with any mental health challenges you may be facing. Through our chat sessions, I will provide a safe and confidential space where you can share your thoughts, feelings, and concerns about your studies.
                                        <br /> <br />My therapeutic approach is based on the latest evidence-based practices and techniques, designed to help students overcome a range of challenges, including anxiety, and relationship issues
                                    </div>

                                    {/* <h3 className="text-xl ml-3 font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-3xl text-white">Luna Chat</h3> */}
                                </div>



                            </div>

                        </div>

                        <div className="flex items-center justify-center flex-wrap w-full">

                            <DashboardCard

                                title={'Messages'}
                                value={formatNum(cardsValue.totalMessages)}
                                icon={<AiOutlineMessage class="dark:text-white" size={25} />}

                            />

                            <DashboardCard

                                title={'Total Chats'}
                                value={formatNum(cardsValue.totalChats)}
                                icon={<BiChat class="dark:text-white" size={25} />}

                            />

                            <DashboardCard

                                title={'This Month Chats'}
                                value={formatNum(cardsValue.thisMonthChats)}
                                icon={<RiTimeLine class="dark:text-white" size={25} />}

                            />
                        </div>

                       

                        {

                            chats !== null && chats.length > 0

                            &&

                            <>

                                <H3 className="mt-7">Recent Chats</H3>

                                <div className="relative overflow-x-auto my-5">

                                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">

                                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Name
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Messages
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Updated At
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {

                                                chats !== null && chats.map((e) => {

                                                    //if status is training, increase on training value

                                                    return (
                                                        <tr key={e.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                                {e.name}
                                                            </th>

                                                            <td className="px-6 py-4">
                                                                <GreenBadge
                                                                    text={formatNum(e.messages)}
                                                                />
                                                            </td>

                                                           
                                                            <td className="px-6 py-4">
                                                                {dateFormat(e.updatedAt)}
                                                            </td>

                                                            <td className="px-6 py-4">

                                                                <div className="flex">

                                                                    <Link to={"/chat/" + e.id}><HiChatBubbleOvalLeft color="#924119" className="mr-2" size={20} /></Link>

                                                                    

                                                                </div>

                                                            </td>


                                                        </tr>
                                                    )
                                                    

                                                })
                                            }



                                        </tbody>

                                    </table>

                                </div>

                            </>
                        }



                    </div>

            }




        </Main>

    );


}

export default Dashboard;