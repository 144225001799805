
import { RiUserLine, RiDashboard2Line, RiChat3Line, RiLock2Line } from "react-icons/ri";
import { BiChat } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";

const Sidebar = ({ whichActive }) => {


    const [active, setActive] = useState({
        dashboard: "",
        profile: "",
        chats: "",
        askLuna: ""
    });

    const navigate = useNavigate();

    useEffect(() => {

        let tempActive = { ...active };

        //tempActive[whichActive] = "bg-gray-300 dark:bg-gray-700";

        tempActive[whichActive] = "bg-amber-800 text-white";

        setActive(tempActive);

    }, [whichActive]);

    return (

        <aside id="default-sidebar" className="z-40 w-56 xl:w-auto h-screen transition-transform -translate-x-full translate-x-0" aria-label="Sidebar">
            <div className="h-full px-3 py-4 overflow-y-auto bg-slate-50 border-r border-slate-200 dark:border-gray-700 dark:bg-gray-800">

                <div className="mb-3 ml-3 mt-3">
                    <span className="font-bold dark:text-white">Menu</span>
                </div>

                <ul className="space-y-2 font-medium">
                    <li>
                        <Link to="/dashboard" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-amber-800 hover:text-white ${active.dashboard}`}>
                            <RiDashboard2Line size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/chat"
                            className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-amber-800 hover:text-white ${active.askLuna}`}
                            onClick={(e) => {
                                e.preventDefault();
                                navigate("/chat", { state: nanoid() });
                            }}
                        >
                            <RiChat3Line size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Ask Luna</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/chats" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-amber-800 hover:text-white ${active.chats}`}>
                            <BiChat size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Chats</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/profile" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-amber-800 hover:text-white ${active.profile}`}>
                            <RiUserLine size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Profile</span>
                        </Link>
                    </li>

                    <li>
                        <Link to="/logout" className={`flex items-center p-2 text-gray-900 rounded dark:text-white hover:bg-amber-800 hover:text-white`}>
                            <RiLock2Line size={22} />
                            <span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
                        </Link>
                    </li>

                </ul>
            </div>
        </aside>


    );

}

export default Sidebar;