import Main from "../Components/Main";
import API from "../Support/API";
import aiImage from "../Images/luna.png";
import { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { marked } from "marked";
import Constants from "../Support/Constants";
import typingImage from "../Images/typing.gif";
import userImage from "../Images/user.png";
import uniqid from 'uniqid';
import { H4 } from "../Components/Basics";
import { useLocation } from "react-router-dom";
import NoticeModal from "../Components/Modals/NoticeModal";

const Chat = () => {

    const [msgs, setMsgs] = useState([]);

    const inputRef = useRef();
    const typingRef = useRef();

    const [isLoading, setIsLoading] = useState(true);

    const { id } = useParams();
    const alreadyStarted = useRef(false);
    const allowSend = useRef(true);

    const [title, setTitle] = useState("");

    const chatRef = useRef();
    const chatId = useRef(null);
    const lastAIMsg = useRef();
    

    const [showNoticeModal, setShowNoticeModal] = useState(0);
    const [notice, setNotice] = useState({
        msg : '',
        title : ''
    });

    useEffect(() => {

        if (id !== undefined) {

            chatId.current = id;

            API.get('messages/' + id)
                .then((e) => {

                    let msgsArray = [];
                    for (const msg of e.messages) {

                        msgsArray.push({
                            'id': uniqid(),
                            'type': msg.type,
                            'msg': msg.message
                        });

                    }

                    setTitle(e.title);
                    setMsgs(msgsArray);
                    scrollChat(100);

                })
                .catch((err) => {
                    throwError(err.message);
                    
                });
        }

    }, [id]);

    const { state } = useLocation();

    useEffect(() => {
        chatId.current = null;
        setMsgs([]);
        setTitle('');
    }, [state]);

    const getReply = async (message) => {

        const postData = {
            'message': message,
            'chatId': chatId.current
        }

        let response;

        try {
            response = await fetch(Constants.apiUrl + "/chat/get-reply", {
                method: 'POST',
                body: JSON.stringify(postData),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            //hide typing
            hideTyping();

            if (!response.ok) {

                console.log('hi')

                if (response.status === 401) {
                    window.location.replace("login");
                    return;
                }

                const responseData = await response.json();

                if (responseData && responseData.msg) {
                    return throwError(responseData.msg);
                } else {
                    return throwError("Something went wrong, please try later.");
                }
            }

        } catch {
            return throwError('Something went wrong, please try later.')
        }

        const reader = response.body.getReader();
        const textDecoder = new TextDecoder();

        //this._createOutputMessage();

        let markedText = "";

        const msgId = uniqid();

        setMsgs((msgs) => {
            return [...msgs, {
                id: msgId,
                type: "ai",
                msg: ""
            }]
        });

        let count = 0;
        while (true) {

            count += 1;

            const {
                value,
                done
            } = await reader.read();
            if (done) break;

            const resultString = textDecoder.decode(value);

            markedText += resultString;

            lastAIMsg.current.innerHTML = marked.parse(markedText);

            if (count % 2 === 0) {
                scrollChat(60);
            }

        }

        setMsgs((msgs) => {
            for (const x in msgs) {
                if (msgs[x].id === msgId) {
                    msgs[x].msg = marked.parse(markedText);
                }
            }
            return [...msgs];
        })

        allowSend.current = true;

    }

    const throwError = (message) => {
        setNotice({
            msg : message,
            title : 'Error'
        });
        setShowNoticeModal((n) => n + 1);
        hideTyping();
        allowSend.current = true;

    }


    const addNewUserMsg = (t = null) => {

        if (!allowSend.current) {
            return;
        }

        let text = "";
        if (typeof t !== 'string') {
            text = inputRef.current.value
        }
        else {
            text = t;
        }

        if (text === "") {
            return;
        }

        inputRef.current.value = "";

        const msgId = uniqid();

        setMsgs((msgs) => {
            return [...msgs, {
                id: msgId,
                type: "user",
                msg: text
            }]
        });

        showTyping();

        if (chatId.current === null) {

            API.post('chat/create', {
                message: text
            })
                .then((e) => {

                    chatId.current = e.chatId;
                    //get ai response
                    getReply(text);
                    setTitle(e.title);
                })
                .catch((err) => {
                    throwError(err.message);
                });
        }
        else {
            //get ai response
            getReply(text);
        }



        setTimeout(() => {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: "smooth",
            });
        }, 200);
    }

    const addToInput = (e) => {
        inputRef.current.value = e.target.innerText

    }

    const scrollChat = (time = 200) => {

        setTimeout(() => {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight + 100,
                behavior: "smooth",
            });
        }, time);
    }

    const formatMsg = (msg) => {

        let newMsg;

        newMsg = marked.parse(msg);

        return newMsg;
    }

    const showTyping = () => {

        typingRef.current.classList.remove('hidden');
        allowSend.current = false;
    }

    const hideTyping = () => {

        typingRef.current.classList.add('hidden');
    }

    const onReadyMsgClick = (index) => {

        const prompts = {
            1: "I am an anxious student, what are some mindfulness activities that I can do to calm myself? ",
            2: "I am in anxious student, can you guide me through a mindfulness activity? ",
            3: "I am anxious when taking tests, what can I do to reduce my anxiety as a student? ",
            4: "Please list some coping skills to help with my anxiety related to my college assignments?",
            5: "How can I manage my time so that I am a less anxious college student?",
            6: "I am a college student and I am anxious. What can I do to decrease my anxiety?",
            7: "What resources do you have to help an anxious college student?  ",
            8: "I just want to talk to you",
            9: "What are some free self-calming strategies for college students?"
        };

        addNewUserMsg(prompts[index]);

    }

    const checkKey = (event) => {

        if (event.key === 'Enter') {
            event.preventDefault();
            addNewUserMsg()
        }
    }

    return (

        <Main yGap={0} active="askLuna">

            <NoticeModal show={showNoticeModal} notice={notice} />

            <div className="chat-container m-auto p-2 lg:max-w-7xl chat-main">

                <div className="mb-3">
                    <H4 className={'text-center text-sm lg:text-lg'}>{title}</H4>
                </div>

                <div ref={chatRef} className="flex items-between flex-col chat-height px-3 hide-scrollbar">

                    {

                        msgs.length === 0 &&

                        <div className="flex items-center flex-col justify-center mt-2">

                            <div className="flex items-center justify-center mb-7">
                                <img src={aiImage} className="mr-2 shadow-lg rounded-full lg:w-12 w-8 mr-3" />
                                <h3 className="text-xl font-extrabold leading-none tracking-tight text-gray-900 md:text-2xl lg:text-3xl dark:text-white">Ask Luna</h3>
                            </div>

                            <p className="dark:text-white text-sm lg:text-xl text-center">Hello! I am Luna, your virtual mental health therapist.<br />I am here to assist you in reducing your anxiety. How may I help you? It is my absolute pleasure.</p>

                            <div className="flex justify-center items-center flex-col md:flex-row">

                                <div className="flex justify-center items-center mt-10">

                                    <div className="flex flex-col items-center justify-center w-[250px]">

                                        <h4 className="mb-7 text-lg font-bold leading-none tracking-tight text-gray-900 md:text-xl lg:text-2xl dark:text-white">Time Management</h4>

                                        <div onClick={(e) => onReadyMsgClick(5)} className="dark:text-white cursor-pointer sm:text-base text-sm bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Managing time to reduce anxiety
                                        </div>

                                        <div onClick={(e) => onReadyMsgClick(8)} className="dark:text-white cursor-pointer sm:text-base text-sm bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Chatting with Luna
                                        </div>

                                        <div onClick={(e) => onReadyMsgClick(7)} className="dark:text-white cursor-pointer sm:text-base text-sm bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Resources for college students to decrease anxiety
                                        </div>

                                    </div>

                                </div>

                                <div className="flex justify-center items-center mt-10 md:ml-10">

                                    <div className="flex flex-col items-center justify-center w-[250px]">

                                        <h4 className="mb-7 text-lg font-bold leading-none tracking-tight text-gray-900 md:text-xl lg:text-2xl dark:text-white">Anxiety</h4>

                                        <div onClick={(e) => onReadyMsgClick(1)} className="dark:text-white sm:text-base text-sm cursor-pointer bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Calming mindfulness activities
                                        </div>

                                        <div onClick={(e) => onReadyMsgClick(2)} className="dark:text-white sm:text-base text-sm cursor-pointer bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Guide me through a mindfulness activity
                                        </div>

                                        <div onClick={(e) => onReadyMsgClick(3)} className="dark:text-white sm:text-base text-sm cursor-pointer bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Reduce test-taking anxiety
                                        </div>

                                    </div>

                                </div>


                                <div className="flex justify-center items-center mt-10 md:ml-10">

                                    <div className="flex flex-col items-center justify-center w-[250px]">

                                        <h4 className="mb-7 text-lg font-bold leading-none tracking-tight text-gray-900 md:text-xl lg:text-2xl dark:text-white">Stress</h4>

                                        <div onClick={(e) => onReadyMsgClick(4)} className="dark:text-white sm:text-base text-sm cursor-pointer bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Coping skills to reduce anxiety related to school assignments
                                        </div>

                                        <div onClick={(e) => onReadyMsgClick(6)} className="dark:text-white sm:text-base text-sm cursor-pointer bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Actions I can take to decrease anxiety
                                        </div>

                                        <div onClick={(e) => onReadyMsgClick(9)} className="dark:text-white sm:text-base text-sm cursor-pointer bg-gray-200 dark:bg-gray-700 p-3 flex items-center justify-center text-center rounded mb-5 w-full min-h-[100px]">
                                            Self calming strategies
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    }


                    {

                        msgs.length > 0 && msgs.map((e, index) => {

                            return (

                                e.type === 'user' ?

                                    <div key={e.id} className="my-3 flex justify-end">

                                        <div className="bg-slate-100 sm:text-base text-sm dark:bg-gray-700 text-black xl:w-3/6 w-10/12 p-5 rounded-lg whitespace-pre-line dark:text-white">
                                            {e.msg}
                                        </div>

                                        <div className="ml-3 flex-shrink-0">
                                            <img src={userImage} className="rounded-full lg:w-12 w-10" />
                                        </div>

                                    </div>

                                    :

                                    //if last ai msg
                                    index === msgs.length - 1 && allowSend.current === false

                                        ?

                                        <div key={e.id} className="flex justify-start my-4">

                                            <div className="flex-shrink-0 mr-3">
                                                <img src={aiImage} className="rounded-full lg:w-12 w-10" />
                                            </div>

                                            <div ref={lastAIMsg} className="bg-amber-800 sm:text-base text-sm text-white xl:w-3/6 w-10/12 p-5 rounded-lg whitespace-pre-line prose"></div>

                                        </div>

                                        :

                                        <div key={e.id} className="flex justify-start my-4">

                                            <div className="flex-shrink-0 mr-3">
                                                <img src={aiImage} className="rounded-full lg:w-12 w-10" />
                                            </div>

                                            <div className="bg-amber-800 sm:text-base text-sm text-white xl:w-3/6 w-10/12 p-5 rounded-lg whitespace-pre-line prose" dangerouslySetInnerHTML={{ __html: formatMsg(e.msg) }} />

                                        </div>

                            );

                        })
                    }

                    <div ref={typingRef} className="flex justify-start my-3 hidden">

                        <div className="flex-shrink-0 mr-3">
                            <img src={aiImage} width={50} className="rounded-full lg:w-12 w-10" />
                        </div>

                        <div className="p-2 flex items-center h-14 rounded-lg whitespace-pre-line">
                            <img src={typingImage} className="lg:w-12 w-10" />
                        </div>

                    </div>


                </div>

                <label className="sr-only">Your message</label>
                <div className="flex items-center justify-center mb-2 w-full h-20">

                    <div className="w-full flex items-center border border-gray-300 py-2 focus:ring-amber-500 focus:border-amber-500 dark:border-gray-600 rounded-full dark:focus:ring-amber-500 dark:focus:border-amber-500">
                        <textarea onKeyDown={checkKey} ref={inputRef} className="sm:text-base text-sm block py-0 hide-scrollbar resize-none h-8 focus:ring-0 border-none rounded-full mx-4 w-full text-gray-900 bg-white dark:bg-gray-900  dark:placeholder-gray-400 dark:text-white" placeholder="Your message..."></textarea>
                    </div>

                    <button onClick={addNewUserMsg} type="submit" className="inline-flex justify-center px-2 py-0 text-amber-800 rounded-full cursor-pointer hover:bg-amber-100 dark:text-amber-500 dark:hover:bg-gray-600">
                        <svg aria-hidden="true" className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path></svg>
                        <span className="sr-only">Send message</span>
                    </button>

                </div>



            </div>






        </Main>

    );


}

export default Chat;